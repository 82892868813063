import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import Card from "../components/v2023/UI/Card/card"
import Opinions from "../components/v2023/Opinions/opinions"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import TecalisAdapts from "../components/v2023/TecalisAdapts/tecalis-adapts"
import CustomerLogos from "../components/v2023/CustomerLogos/customer-logos"

const AntiFraudControls = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page.edges[0].node
    const helpers = data.helpers.frontmatter

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg color-section purple-dark m-b-80">
                    <div className="container">
                        <div className="container__50">
                            <div className="tag tag--highlight">{page.name}</div>
                            <h1 className="merriweather">{page.hero_title}</h1>
                            <div className="container__text">
                                <ReactMarkdown children={page.hero_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <div className="button__wrapper">
                                <Link to={helpers.request_demo_path}>
                                    <button className="button button--ghost button--xl">{page.hero_button}</button>
                                </Link>
                            </div>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <div className="gif">
                                <lottie-player src="/animations/anti-fraud-controls.json" background="transparent" speed="1" loop autoplay />
                            </div>
                        </div>
                    </div>
                </div>
                <CustomerLogos />
                <div className="main__section main__section--50 main__section--50-invert main__section--50--sm">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.tsp_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.tsp_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.tsp_image.alternativeText} image={getImage(page.tsp_image.localFile)} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.verification_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.verification_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className="grid-lg-2 ai-center">
                            <div>
                                <GatsbyImage alt={page.verification_image_one.alternativeText} image={getImage(page.verification_image_one.localFile)} />
                            </div>
                            <div>
                                <GatsbyImage alt={page.verification_image_two.alternativeText} image={getImage(page.verification_image_two.localFile)} />
                            </div>
                        </div>
                        <div className="grid-lg-4">
                            {page.verification_items.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <Card
                                    key={index}
                                    name={item.title}
                                    image={item.image_new}
                                    alternativeText={item.image_new.alternativeText}
                                    description={`${item.short_description}${item.long_description}`}
                                    animation={animation}
                                />
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50  main__section--50--sm">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.management_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown
                                    children={page.management_description_one}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                            <div className="tile">
                                <div className="tile__body">
                                    <ReactMarkdown
                                        children={page.management_description_two}
                                        rehypePlugins={[rehypeRaw]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.management_image.alternativeText} image={getImage(page.management_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <div className="main__section">
                    <div className="container">
                        <div className="grid-lg-4">
                            {page.management_items.map((item, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                                return <div className="image-group" key={index} {...animation} >
                                    <img src={item.icon.localFile.publicURL} alt={item.icon.alternativeText} />
                                    <div className="image-group__text">
                                        <h4>{item.title}</h4>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--sm main__section--50-invert">
                    <div className="container">
                        <div className="container__50">
                            <ReactMarkdown children={page.agility_title_new} rehypePlugins={[rehypeRaw]} />
                            <div className="container__text">
                                <ReactMarkdown children={page.agility_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.agility_image_new.alternativeText} image={getImage(page.agility_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--sm color-section gray">
                    <div className="container">
                        <div className="container__50">
                            <div className="grid-sm-2">
                                {page.media_items.map((item, index) => (
                                    <div className="numbered-list numbered-list--horizontal" key={index}>
                                        <div className="numbered-list__number">{item.order}</div>
                                        <div className="numbered-list__info">
                                            <h4>{item.title}</h4>
                                            <div className="numbered-list__info__text">
                                                <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <GatsbyImage alt={page.media_image.alternativeText} image={getImage(page.media_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <Opinions lang={lang} />
                <TecalisAdapts lang={lang} />
                <ReadyToTalk lang={lang} />
            </main>
        </NewLayout>
    )
}

export default AntiFraudControls

export const antiFraudQuery = graphql`
    query ($langKey: String) {
        page: allStrapi2022AntiFraud ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    name
                    hero_title
                    hero_description
                    hero_button
                    hero_anim_desktop
                    hero_anim_mobile
                    tsp_title
                    tsp_description
                    tsp_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    verification_title
                    verification_description
                    verification_image_one {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    verification_image_two {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 308
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    verification_items {
                        order
                        title
                        short_description
                        long_description
                        image {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                        image_new {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                    }
                    management_title
                    management_description_one
                    management_description_two
                    management_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    management_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    management_items {
                        order
                        title
                        description
                        icon {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                    }
                    agility_title
                    agility_title_new
                    agility_description
                    agility_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    agility_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    media_items {
                        order
                        title
                        description
                    }
                    media_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    media_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
        helpers: markdownRemark (
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "helpers"
                }
            }
        ) {
            frontmatter {
                trust_us
                last_news
                blog_path
                about_us_path
                request_demo_path
            }
        }
        image: file(relativePath: { eq: "background-blur.png" }) {
            sharp:childImageSharp {
                fluid {
                    srcSetWebp
                }
            }
        }
    }
`
